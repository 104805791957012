/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { getUsersDetails, SortListByCreateAt } from "../../../utils/common";
import { ActionTypes } from "./actions";
import { IChatRes } from "./types";

const initialState: IChatRes = {
    currentChatId: "",
    chatMessageList: {},
    groupMessages: {},
    usersList: [],
    notificationList: [],
    chatSummaryList: [],
    ChannelDetails: null,
    groupList: [],
    chatUsersList: {
        single: [],
        group: [],
        revision: [],
        project: []
    },
    projectGroupList: [],
    emojilist: {
        All: [],
        Smileys_Emotion: [],
        Animals_Nature: [],
        Food_Drink: [],
        Travel_Places: [],
        Activities: [],
        Objects: [],
        Flags: []
    },
    groupDetails:[],
};

const chatReducer = (state: IChatRes = initialState, action): IChatRes => {
    switch (action.type) {
        case ActionTypes.SET_STORE_UPDATE_USER_CHAT: {
            const { id, group_id } = action.payload;
            const groupListData = state?.chatUsersList?.single?.map((item:any)=>{
                if(item?.id === id){
                    return{
                        ...item,
                        group_id: group_id,
                        to_id: id,
                    }
                }else{
                    return item;
                }
            })
            return {
                ...state,
                chatUsersList:{
                    ...state?.chatUsersList,
                    single: groupListData
                },
            };
        }
        case ActionTypes.SET_STORE_MESSAGE: {
            // const newMessage = action.payload;
            const messages = action.payload?.data;
            const group_id = action.payload?.group_id;
            const oldMessages = state?.chatMessageList?.[group_id] || [];
            const newMessage = []

            if(oldMessages?.length){
                oldMessages?.map((item)=>{
                    if(item?.id !== messages?.id){
                        newMessage.push(item);
                    }
                })
                const check = oldMessages?.find(item=>item?.id == messages?.id);
                if(!check){
                    newMessage?.push(messages)
                }else{
                    newMessage?.push(messages)
                }
            }else{
                newMessage?.push(messages);
            }

            return {
                ...state,
                chatMessageList:{
                    ...state?.chatMessageList,
                    [group_id]: SortListByCreateAt(newMessage, true),
                }
            };
        }
        case ActionTypes?.SET_STORE_MESSAGE_LIST:{
            const messagesList = action?.payload?.data?.messages;
            const message_group_id = action?.payload?.group_id;
            const oldMessages = state?.chatMessageList?.[action?.payload?.group_id];
            if(oldMessages){
                const listMessages = [];
                if(messagesList?.length){
                    messagesList?.map((item)=>listMessages.push(item));
                }

                if(oldMessages?.length){
                    oldMessages?.map((item)=>{
                        const checkData = listMessages.find(item1=>item1?.id == item?.id)
                        if(!checkData){
                            listMessages.push(item);
                        }
                    });
                }
                return{
                    ...state,
                    chatMessageList:{
                        ...state?.chatMessageList,
                        [message_group_id]: SortListByCreateAt(listMessages, true),
                    }
                }
            }else{
                return{
                    ...state,
                    chatMessageList:{
                        ...state?.chatMessageList,
                        [message_group_id]: SortListByCreateAt(messagesList, true),
                    }
                }
            }
        }
        case ActionTypes.SET_STORE_CHANNEL_DETAILS: {
            if(!action.payload) return {...state, ChannelDetails: initialState?.ChannelDetails};
            return {
                ...state,
                ChannelDetails: {
                    ...action.payload,
                    is_group: action?.payload?.group_type == "group" || action?.payload?.group_type == "project"
                },
            };
        }

        case ActionTypes.UPDATE_STORE_CHANNEL_DETAILS: {
            if(action.payload?.updateData?.name!==state?.ChannelDetails?.name){
                
                return {...state, ChannelDetails: {...state?.ChannelDetails,name: action.payload?.updateData?.name}};
            } 
            else if(action.payload?.updateData?.group_img!==state?.ChannelDetails?.name){
                return {...state, ChannelDetails: {...state?.ChannelDetails,group_img: action.payload?.updateData?.group_img}};
            }
            return {...state};
        }

        case ActionTypes.ADD_USER_TO_CHAT_LIST: {
            const newUser = action?.payload;
            const userExists = state?.usersList?.some(item => item.id === newUser.id);
            const usersList = [];
            if(userExists){
                state?.usersList?.map((item)=>{
                    if(item.id === newUser.id){
                        usersList?.push(newUser)
                    }else{
                        usersList?.push(item)
                    }
                });
            }else{
                state?.usersList?.map((item)=>usersList?.push(item));
                usersList?.push(newUser)
            }
            return {
                ...state,
                usersList: usersList
            };
        }

        case ActionTypes.SET_ADD_NOTIFICATION_LIST: {
            const newNotifications = action.payload;
            return {
                ...state,
                notificationList: [...state?.notificationList, newNotifications],
            };
        }
        case ActionTypes.SET_STORE_GROUP_ADD: {
            // console.log("action?.payload::",action?.payload)
            const newGroup = action?.payload || {}; // Default to an empty object if payload is undefined
            if (!newGroup.id) return state;
            let groupListData = [];

            if(state?.chatUsersList?.group?.length){
                state?.chatUsersList?.group?.map((item)=>{
                    if(item?.group_id != newGroup?.group_id){
                        groupListData.push(item)
                    }
                })
            }
            const newGroup2={
                ...newGroup,
                group_id:newGroup?.id
            }
            groupListData.push(newGroup2)
            // console.log("groupListData::",groupListData)
            return {
                ...state,
                chatUsersList:{
                    ...state?.chatUsersList,
                    group: groupListData
                },
            };
        }
        case ActionTypes.SET_STORE_ADD_USER_CHAT: {
            const newGroup = action?.payload || {}; // Default to an empty object if payload is undefined
            if (!newGroup.id) return state;
            let groupListData = [];

            if(state?.chatUsersList?.single?.length){
                state?.chatUsersList?.single?.map((item)=>{
                    if(item?.group_id != newGroup?.group_id){
                        groupListData.push(item)
                    }
                })
            }
            groupListData.push(newGroup)

            return {
                ...state,
                chatUsersList:{
                    ...state?.chatUsersList,
                    single: groupListData
                },
            };
        }
        case ActionTypes.SET_CREATE_PROJECT_GROUP: {
            const newProjectGroup = action?.payload || {}; 
            if (!newProjectGroup.id) return state;

            const projectGroupExists = Array.isArray(state?.projectGroupList) && state?.groupList?.some(item => item.id === newProjectGroup.id);

            const projectGroupList = projectGroupExists
                ? state?.projectGroupList?.map?.(group =>
                    group?.id === newProjectGroup.id ? { ...group, ...newProjectGroup } : group
                )
                : [...(Array.isArray(state?.projectGroupList) ? state?.projectGroupList : []), newProjectGroup];

            return {
                ...state,
                projectGroupList,
            };
        }
        case ActionTypes.SET_FILTERED_GROUPS: {
            const chatUsersItems = {
                single: action?.payload?.single || [],
                group: action?.payload?.group || [],
                revision: action?.payload?.revision || [],
                project: action?.payload?.project || [],
            };
            // console.log("chatUsersItems::",chatUsersItems)
            if(state?.chatUsersList?.single){
                chatUsersItems.single = action?.payload?.single?.map((item)=>{
                    const oldUserChat = state?.chatUsersList?.single?.find((item1)=>item1?.group_id ==  item?.group_id);
                    if(oldUserChat?.user_details){
                        return{
                            ...item,
                            ...oldUserChat,
                            user_details: oldUserChat?.user_details,
                            user_id: oldUserChat?.user_details?.id,
                            to_id: oldUserChat?.user_details?.id,
                        }
                    }else{
                        return item
                    }
                });
                state?.chatUsersList?.single?.map((item)=>{
                    const oldUserChat = chatUsersItems?.single?.find?.((item1)=>item1?.group_id ==  item?.group_id);
                    if(!oldUserChat){
                        chatUsersItems?.single?.push(item);
                    }
                })
            }
            if(state?.chatUsersList?.group){
                chatUsersItems.group = action?.payload?.group?.map((item)=>{
                    const oldUserChat = state?.chatUsersList?.group?.find((item1)=>item1?.group_id ==  item?.group_id || item1?.id==item?.group_id);
                    if(oldUserChat){
                        return{
                            ...oldUserChat,
                            ...item,
                        }
                    }else{
                        return item
                    }
                });
                state?.chatUsersList?.group?.map((item)=>{
                    const oldUserChat = chatUsersItems?.group?.find?.((item1)=>item1?.group_id ==  item?.group_id);
                    if(!oldUserChat){
                        chatUsersItems?.group?.push(item);
                    }
                })
            }
            if(state?.chatUsersList?.project){
                chatUsersItems.project = action?.payload?.project?.map((item)=>{
                    const oldUserChat = state?.chatUsersList?.project?.find((item1)=>item1?.group_id ==  item?.group_id);
                    if(oldUserChat){
                        return{
                            ...oldUserChat,
                            ...item,
                        }
                    }else{
                        return item
                    }
                });
                state?.chatUsersList?.project?.map((item)=>{
                    const oldUserChat = chatUsersItems?.project?.find?.((item1)=>item1?.group_id ==  item?.group_id);
                    if(!oldUserChat){
                        chatUsersItems?.project?.push(item);
                    }
                })
            }
            return {
                ...state,
                chatUsersList: chatUsersItems,
            };
        }
        case ActionTypes.ADD_GROUP_TO_LIST: {
            const newGroup = action?.payload;
            const groupExists = state?.groupList?.some(item => item.id === newGroup.id);

            const groupList = groupExists
                ? state.groupList
                : [...state.groupList, newGroup];

            return {
                ...state,
                groupList: groupList
            };
        }
        case ActionTypes.ADD_PROJECT_GROUP_LIST: {
            const newProjectGroup = action?.payload;
            const projectGroupExists = state?.projectGroupList?.some(item => item.id === newProjectGroup.id);

            const projectGroupList = projectGroupExists
                ? state.projectGroupList
                : [...state.projectGroupList, newProjectGroup];

            return {
                ...state,
                projectGroupList: projectGroupList
            };
        }
        case ActionTypes.SET_STORE_UPDATE_SINGLE_CHAT_USERS_LIST: {
            const singleUser = state?.chatUsersList?.single?.map((item)=>{
                const payload: any = {
                    ...item,
                }
                const getUsersId = item?.users?.filter?.((item)=>item != action?.payload?.current_user_id )
                if(getUsersId?.length){
                    const getUserDetails = action.payload?.users_list?.find?.(items=>items?.id === getUsersId[0]);
                    if(getUserDetails){
                        payload.user_details = {
                            ...getUserDetails,
                            api_permissions:"",
                            role_permissions:[],
                        };
                        payload.user_id = getUserDetails?.id;
                        payload.to_id = getUserDetails?.id;
                    }
                }
                if(state?.chatMessageList?.[item?.group_id]){
                    const listChat = SortListByCreateAt(state?.chatMessageList?.[item?.group_id]);
          
                    if(listChat?.length){
                        const messages =  listChat[0];
                        payload.message = messages?.message;
                        payload.created_at = messages?.created_at;
                        payload.updated_at = messages?.updated_at;
                        payload.last_message = messages;
                    }
                }
                return payload;
            })
            const groupChatList = getUsersDetails(state?.chatUsersList?.group, action, state);
            const projectChatList = getUsersDetails(state?.chatUsersList?.project, action, state);
            return {
                ...state,
                chatUsersList:{
                    ...state?.chatUsersList,
                    single: singleUser,
                    group: groupChatList,
                    project: projectChatList,
                }
            };
        }
        case ActionTypes.SET_STORE_UPDATE_CHAT_USERS: {
            const messagesData = action?.payload?.data;
            const group_type = action?.payload?.group_type;
            const singleUser = state?.chatUsersList?.[group_type]?.map((item)=>{
                const payload: any = {
                    ...item,
                }
                if(messagesData?.group_id === item?.group_id){
                    return {
                        ...item,
                        ...messagesData
                    };
                }
                return payload;
            })
            return {
                ...state,
                chatUsersList:{
                    ...state?.chatUsersList,
                    [group_type]: singleUser
                }
            };
        }
        case ActionTypes.SET_STORE_UPDATE_CHAT_COUNT: {
            const messagesData = action?.payload?.data;
            const group_type = action?.payload?.group_type;
            const singleUser = state?.chatUsersList?.[group_type]?.map((item)=>{
                const payload: any = {
                    ...item,
                    message_count: item?.message_count || 0,
                }
                if(messagesData?.group_id === item?.group_id){
                    payload.message = messagesData?.message
                    payload.last_message = messagesData
                    payload.created_at = messagesData?.created_at
                    payload.updated_at = messagesData?.updated_at
                    if(item?.message_count){
                        payload.message_count = item.message_count + 1
                    }else{
                        payload.message_count = 1;
                    }
                }
                return payload;
            })
            return {
                ...state,
                chatUsersList:{
                    ...state?.chatUsersList,
                    [group_type]: singleUser
                }
            };
        }
        case ActionTypes.ADMIN_LOGOUT:{
            return initialState;
        }
        case ActionTypes.EMOJI_LIST_GET:{
            if(!action?.payload){
                return{
                    ...state,
                    emojilist: initialState?.emojilist
                }
            }
            return {
                ...state,
                emojilist: {
                  All: action.payload,
                  Smileys_Emotion: action.payload.filter((elm) => elm.category === 'Smileys & Emotion'),
                  Animals_Nature: action.payload.filter((elm) => elm.category === 'Animals & Nature'),
                  Food_Drink: action.payload.filter((elm) => elm.category === 'Food & Drink'),
                  Travel_Places: action.payload.filter((elm) => elm.category === 'Travel & Places'),
                  Activities: action.payload.filter((elm) => elm.category === 'Activities'),
                  Objects: action.payload.filter((elm) => elm.category === 'Objects'),
                  Flags: action.payload.filter((elm) => elm.category === 'Flags'),
                },
            };
        }
        case ActionTypes.SET_REMOVE_USER_FROM_GROUP: {
            // console.log("action.payload::",action.payload)
            const { group_id, user_id } = action?.payload || {}; 
        
            if (!group_id || !user_id) return state; 
        
            const updatedGroupList = state?.chatUsersList?.group?.map(group => {
                if (group?.group_id === group_id) {
                    return {
                        ...group,
                        users: group?.users?.length>0&&group?.users?.filter(user => user !== user_id)
                    };
                }
                return group;
            });
            const updatedChannelDetails={
                ...state?.ChannelDetails,
                users: state?.ChannelDetails?.users?.filter?.(user => user !== user_id),
                userDetails: state?.ChannelDetails?.usersDetails?.filter( userDetail => userDetail.id !== user_id)
            }
        
            return {
                ...state,
                chatUsersList: {
                    ...state?.chatUsersList,
                    group: updatedGroupList,
                },
                ChannelDetails:updatedChannelDetails,
            };
        }
        
        case ActionTypes.SET_ADD_USER_TO_GROUP: {
            const { id, users } = action?.payload || {}; 
            if (!id || !users) return state;
            const userArray = users.split(',');
        
            const updatedGroupList = state?.chatUsersList?.group?.map(group => {
                if (group?.group_id === id) {
                    return {
                        ...group,
                        users: userArray
                    };
                }
                return group;
            });
            const updatedChannelDetails = {
                ...state?.ChannelDetails,
                users: [...state?.ChannelDetails?.users || [], ...userArray], 
                userDetails: [
                    ...state?.ChannelDetails?.usersDetails || [],
                    ...userArray.map(user => ({ id: user })) 
                ],
            };
        
            return {
                ...state,
                chatUsersList: {
                    ...state?.chatUsersList,
                    group: updatedGroupList,
                },
                ChannelDetails:updatedChannelDetails,
            };
        }  
        case ActionTypes.SET_GROUP_DETAILS: {
            return {
                ...state,
                groupDetails: [action.payload]
            };
        }      
        default:
            return state;
    }
};

export default chatReducer;
